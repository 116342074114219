<template>
  <div class="inner-section">
         <card>
           <template v-slot:searchHeaderTitle>
              <h4 class="card-title">{{ $t('elearning_tim.allowance') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:searchBody>
              <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.training_type_id"
                      :options="trainingTypeList"
                      id="training_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      disabled
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                    <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_id"
                          :options="batchList"
                          id="batch_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <b-button size="sm" variant="primary" @click="searchData">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
        </card>
        <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.allowance') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <!-- <template v-slot:headerAction>
            <router-link to="allowance-form" :class="'btn btn_add_new font-weight-bold'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link>
          </template> -->
          <template v-slot:headerAction>
            <router-link to="allowance-form" :class="'btn btn-add font-weight-bold'"> <i class="fas fa-plus"> </i> {{  $t('globalTrans.add_new') }}</router-link>
          </template>
          <!-- <template v-slot:headerAction>
            <router-link to="other-trainee-trainer-registration-form" :class="'btn btn-success text-light'">{{  $t('globalTrans.add_new') }}</router-link>
          </template> -->
          <template v-slot:body>
              <b-overlay :show="loadingState">
                <b-row>
                    <b-col sm="12">
                    <div class="quick-filter-wrapper">
                        <div class="quick-filter-left">
                        <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                            <b-dropdown-form>
                            <div
                                class="form-group"
                                v-for="(field, index) in labelData"
                                :key="index"
                                >
                                <b-form-checkbox
                                    :id="'checkbox-' + field.label"
                                    v-model="field.show"
                                    :name="'checkbox-' + field.label"
                                    value=1
                                    unchecked-value=0
                                >
                                    {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                </b-form-checkbox>
                            </div>
                            </b-dropdown-form>
                        </b-dropdown>
                        </div>
                        <div class="quick-filter-right">
                        <b-form-group
                            :label="$t('menu.perpage')"
                            label-for="per-page-select"
                        >
                            <b-form-select
                            id="per-page-select"
                            v-model="search.limit"
                            :options="pageOptions"
                            size="sm"
                            ></b-form-select>
                        </b-form-group>
                        </div>
                    </div>
                    </b-col>
                </b-row>
                  <b-row>
                      <b-col md="12" class="table-responsive">
                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" thead-class="table_head" striped bordered hover :items="listData" :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                              <template v-slot:cell(serial)="data">
                              {{ $n(data.item.serial + ' ' + pagination.slOffset) }}
                              </template>
                              <template v-slot:cell(type_name)="data">
                              <span class="capitalize">{{ data.item.type_name }}</span>
                              </template>
                              <template v-slot:cell(attachment)="data">
                                <a target = '_blank' v-if="data.item.attachment" :href="trainingElearningServiceBaseUrl + 'storage/uploads/attachment/' + data.item.attachment" download>({{$t('globalTrans.view_download')}})</a>
                                <!-- <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+data.item.attachment" fluid alt="Right Logo"></b-img> -->
                              </template>
                              <template v-slot:cell(status)="data">
                                <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                              </template>
                              <template v-slot:cell(action)="data">
                                  <!-- <b-button variant="btn btn-sm btn-success" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i></b-button> -->
                                  <!-- <b-button v-b-modal.modal-4 variant="btn btn-sm btn-info" size="sm" @click="edit(data.item)">
                                    <i class="fas fa-eye"></i>
                                  </b-button> -->
                                  <a href="javascript:" :class="'btn btn_table_action table_action_edit'"  v-b-modal.modal-4 @click="edit(data.item)"><i class="fas fa-paperclip"></i></a>
                                  <router-link :to="`allowance-details?circilarMemoNo=${data.item.circular_memo_no}`" :class="'btn btn_table_action table_action_status'"><i class="fas fa-eye"></i></router-link>
                                  <!-- <router-link v-if="data.item.status == 1" title="Edit" :to="`/training-e-learning-service/tpm/course-module-form?id=${data.item.id}`" class=" btn btn-sm btn-warning"><i class="ri-ball-pen-fill m-0"></i></router-link> -->
                              </template>
                          </b-table>
                        </slot>
                        <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                  </b-row>
              </b-overlay>
          </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.other_trainee_trainer_registration') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
      </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentHonorarumList, circularData, circularPublicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
// import Details from './Details'
import Store from '@/store'
import Form from './FormV'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
        return {
          circularLoading: false,
          sortBy: '',
          sortDirection: '',
          sortDesc: '',
          search: {
            org_id: 0,
            batch_id: 0,
            circular_memo_no: 0,
            training_type_id: 0,
            training_title_id: 0,
            training_status: 1,
            payment_type: 'Trainee',
            fiscal_year_id: 0,
            limit: 20
          },
          batchList: [],
          circularList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          trainingCategoryList: [],
          labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '25%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
          { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
          { labels: 'elearning_config.batch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
          // { labels: 'globalTrans.attachment', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
          // { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          // { labels: 'elearning_tim.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          // { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
          // { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
        ]
      }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      formTitle () {
          return this.$t('elearning_tim.allowance')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
          // const labels = [
          //     { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          //     { label: this.$t('globalTrans.course'), class: 'text-left' },
          //     { label: this.$t('elearning_tpm.course_module'), class: 'text-left' },
          //     { label: this.$t('elearning_tpm.course_module_details'), class: 'text-left' },
          //     { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
          //     { label: this.$t('elearning_config.organization'), class: 'text-center' },
          //     { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          //      { label: this.$t('globalTrans.attachment'), class: 'text-center' },
          //     { label: this.$t('globalTrans.status'), class: 'text-center' },
          //     { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
          // ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'serial' },
            //   { key: 'course_name_bn' },
            //   { key: 'course_module_bn' },
            //   { key: 'course_module_details_bn' },
              { key: 'circular_memo_no' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              { key: 'training_type_bn' },
              { key: 'batch_bn' },
              // { key: 'attachment' },
            //   { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'serial' },
            //   { key: 'course_name' },
            //   { key: 'course_module' },
            //   { key: 'course_module_details' },
              { key: 'circular_memo_no' },
              { key: 'org' },
              { key: 'training_type' },
              { key: 'training_title' },
              { key: 'batch' },
              // { key: 'attachment' },
            //   { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
      this.getCircularList()
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.currentFiscalYearId
        })
        this.loadData()
    },
    watch: {
      'search.org_id': function (newValue) {
        this.batchList = this.getBatchList(newValue)
      },
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      async getCircularMemoNo (getCircularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
      if (!result.success) {
        this.search.org_id = 0
        this.search.training_title_id = 0
        this.search.training_type_id = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.search.org_id = trData.org_id
        this.search.training_title_id = trData.training_title_id
        this.search.training_type_id = trData.training_type_id
        this.load = false
      }
      this.load = false
    },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      getBatchList (orgId) {
        const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
        if (orgId) {
          return batchList.filter(item => item.org_id === orgId)
        }
        return batchList
      },
      edit (item) {
        this.editItemId = item.id
      },
      finalSave (item) {
        // this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseModuleFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
        //   this.changeStatus(trainingElearningServiceBaseUrl, courseModuleToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data, this.search.limit)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          return Object.assign({}, item, { serial: index }, orgData, fiscalYearData, batchData, trainingTitleData, trainingTypeData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
